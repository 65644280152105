/* eslint-disable */
import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  StatusBar,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Platform,
  Keyboard,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import { ApolloConsumer, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { addNotification } from "../App";
import appConfig from "../app.json";

import localforage from "localforage";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";
import Fingerprint2 from "fingerprintjs2";
import { handleError } from "../libs/errors";

const styles = StyleSheet.create({
  schoolTalkImage: {
    marginTop: 20,
    width: "100%",
    minHeight: 50,
    height: "15%",
    resizeMode: "contain",
  },
  owlImage: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
    height: "20%",
    resizeMode: "contain",
  },
  loginText: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 14,
  },
  formControl: {
    borderColor: "rgba(197, 214, 222, 0.7)",
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 8,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#fff",
    fontSize: 18,
    textAlign: "center",
    color: "#888",
    width: window.innerWidth * 0.7,
    height: 42,
  },
  buttonInfo: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 20,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 23,
    textAlign: "center",
    color: "#fff",
    fontSize: 18,
    borderColor: "rgb(0, 168, 255);",
    backgroundColor: "rgb(0, 168, 255);",
    width: window.innerWidth * 0.7,
    height: 46,
  },
  buttonOutlineInfo: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 8,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 23,
    textAlign: "center",
    color: "#fff",
    fontSize: 18,
    borderWidth: 3,
    borderColor: "#00b894",
    backgroundColor: "#00b894",
    width: window.innerWidth * 0.7,
    height: 46,
  },
  container: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  inner: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  tocContainer: {
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
  },
  buttonWhatsapp: {
    paddingTop: 12,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    //marginTop: 20,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 23,
    textAlign: "center",
    color: "#fff",
    fontSize: 18,
    borderColor: "rgb(0, 168, 255);",
    backgroundColor: "rgb(0, 168, 255);",
    width: window.innerWidth * 0.7,
    height: 46,
  },
});

class Login extends Component {
  state = {
    // Rama
    username: process.env.NODE_ENV !== "production" ? "089624809625" : "",
    password: process.env.NODE_ENV !== "production" ? "123123" : "",

    // // Debug
    // username: process.env.NODE_ENV !== "production" ? "082244027927" : "",
    // password: process.env.NODE_ENV !== "production" ? "7519035" : "",

    loading: false,
    deviceInfo: {},
  };

  componentDidMount = async () => {
    let getUsername = await localforage.getItem("username", (err, val) => {
      if (err !== null) {
        return null;
      }
      return val;
    });

    if (getUsername !== null) {
      this.props.history.push({
        pathname: "/explore",
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
    // try {
    //   this.setState({
    //     deviceInfo: await getDeviceInfo()
    //   });
    // } catch (err) {
    //   handleError(err);
    // }
  };

  requestLocationPermission = async () => {
    // try {
    //   const granted = await PermissionsAndroid.request(
    //     PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
    //     {
    //       title: "School Talk Location Permission",
    //       message:
    //         "School Talk needs access to your location " +
    //         "so we can provide more accurate information for you."
    //     }
    //   );
    //   if (granted === PermissionsAndroid.RESULTS.GRANTED) {
    //     console.log("You can use the camera");
    //   } else {
    //     console.log("Camera permission denied");
    //   }
    // } catch (err) {
    //   console.warn(err);
    // }
  };

  handleSubmit = async (e) => {
    this.setState({
      isLoading: true,
    });
    try {
      const { username, password } = this.state;
      if (!username || !password) {
        throw {
          message: "Invalid username or password!",
        };
      }

      let browserInfo = {};
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          Fingerprint2.get((components) => {
            var values = components.map((c) => {
              // console.log(c)
              return c.value;
            });
            var hashId = Fingerprint2.x64hash128(values.join(""), 31);
            browserInfo = {
              uniqueId: hashId,
            };
            resolve();
          });
        }, 800);
      });

      var objappVersion = navigator.appVersion;
      var objAgent = navigator.userAgent;
      var objbrowserName = navigator.appName;
      var objfullVersion = "" + parseFloat(navigator.appVersion);
      let objBrMajorVersion = parseInt(navigator.appVersion, 10);
      let objOffsetName, objOffsetVersion, ix;

      let browserVersion;
      // console.log(objAgent)
      if ((objOffsetVersion = objAgent.indexOf("Chrome")) !== -1) {
        objbrowserName = "Chrome";
        const version = objAgent.slice(objOffsetVersion + 7);
        browserVersion = version.slice(0, 12);
      } else if ((objOffsetVersion = objAgent.indexOf("Safari")) !== -1) {
        objbrowserName = "Safari";
        objfullVersion = objAgent.substring(objOffsetVersion + 7);
        if ((objOffsetVersion = objAgent.indexOf("Version")) !== -1) {
          objfullVersion = objAgent.substring(objOffsetVersion + 8);

          browserVersion = objfullVersion.slice(26, 32);
        }
      } else {
        objbrowserName = "Mozilla";
        browserVersion = objfullVersion;
      }

      browserInfo = {
        ...browserInfo,
        objbrowserName,
        browserVersion,
      };

      // if (!firebase.apps.length) {
      //   // console.log("Initialize firebase...");
      //   firebase.initializeApp(firebaseConfig);
      // }
      // if (firebase.messaging.isSupported()) {
      //   const messaging = firebase.messaging();
      //   await messaging.requestPermission();
      // }

      var result = await this.props.logInWithouExpiration({
        variables: {
          username,
          password,
          installationId: browserInfo.uniqueId,
        },
      });
      const session = result.data.logInWithouExpiration;
      console.log("Session", session);
      localforage.setItem("accountSession", JSON.stringify(session));

      // if (firebase.messaging.isSupported()) {
      //   const messaging = firebase.messaging();
      //   const fcmToken = await messaging.getToken();
      //   localforage.setItem("fcm-token", fcmToken);
      //   await this.props.resubscribeTopicForPushNotification({
      //     variables: {
      //       fcmToken,
      //     },
      //   });
      // }

      setTimeout(() => {
        window.location = "/explore";
      }, 1000);
    } catch (err) {
      console.log(err);
      handleError(err);
      // addNotification({
      //   message: "Invalid username or password",
      //   level: "danger"
      // });
      // alert("Error! Invalid Username");
    }
    this.setState({
      isLoading: false,
    });
  };

  handleInput = (key) => (text) => {
    // console.log(key, text);
    this.setState({
      [key]: text,
    });
  };

  redirectLoginWhatsapp = (e) => {
    console.log("Login by Whatsapp!");
  };

  redirectLoginGoogle = (e) => {
    console.log("Login by Google!");
  };
  render() {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : null}
        // style={{
        //   flex: 1,
        // }}
        contentContainerStyle={{
          flex: 1,
          alignItems: "center",
        }}
        enabled={false}
      >
        {this.state.loading ? (
          //   <LoadingOverlay visible={true} />
          <div />
        ) : (
          <StatusBar backgroundColor="#f0f3f5" barStyle="dark-content" />
        )}
        <BackgroundImage />
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <View style={styles.inner}>
            <Image
              style={styles.schoolTalkImage}
              source={require("../assets/logo-st-label.png")}
            />
            {this.state.deviceInfo ? (
              <Text
                style={{
                  color: "#0984e3",
                  fontSize: 16,
                  textAlign: "right",
                  width: "100%",
                  marginTop: -10,
                  marginBottom: 20,
                  marginRight: 60,
                  fontWeight: "bold",
                }}
              >
                Version {appConfig.version}
              </Text>
            ) : null}
            <Image
              style={styles.owlImage}
              source={require("../assets/owl-laptop-study.png")}
            />
            <Text style={styles.loginText}>Silahkan Login</Text>
            <TextInput
              style={styles.formControl}
              underlineColorAndroid="rgba(0,0,0,0)"
              autoCorrect={true}
              placeholderTextColor="#ccc"
              placeholder="Email or Phone Number"
              value={this.state.username}
              onChangeText={this.handleInput("username")}
              keyboardType="email-address"
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
            />
            <TextInput
              style={styles.formControl}
              secureTextEntry={true}
              underlineColorAndroid="rgba(0,0,0,0)"
              autoCorrect={true}
              placeholderTextColor="#ccc"
              placeholder="Password"
              value={this.state.password}
              onChangeText={this.handleInput("password")}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
            />
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={this.state.isLoading ? null : this.handleSubmit}
            >
              <Text style={styles.buttonInfo}>
                {this.state.isLoading ? (
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ color: "white", fontSize: 20 }}
                  />
                ) : (
                  <i
                    className="fa fa-unlock-alt"
                    style={{ color: "white", fontSize: 20 }}
                  />
                )}
                &nbsp; {this.state.isLoading ? "Loading..." : "Login"}
              </Text>
            </TouchableOpacity>
            <Text style={{ color: "#464646", marginVertical: 5 }}>
              atau masuk dengan
            </Text>
            <TouchableOpacity
              activeOpacity={0.8}
              onPress={() =>
                this.props.history.push({
                  pathname: "/login_whatsapp",
                })
              }
            >
              <View
                style={{
                  backgroundColor: "#128C7E",
                  paddingTop: "0.5rem",
                  paddingRight: "0.5rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "0.5rem",
                  borderRadius: 23,
                  borderColor: "#ced4da",
                  borderWidth: "1px",
                  width: window.innerWidth * 0.7,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ color: "#fff", fontSize: "18px" }}>
                  {" "}
                  <Image
                    style={{
                      width: 18,
                      height: 18,
                      margin: "auto",
                    }}
                    source={require("../assets/icon-whatsapp.png")}
                  />{" "}
                  Whatsapp
                </Text>
              </View>
            </TouchableOpacity>
            {/* <TouchableOpacity
              activeOpacity={0.8}
              onPress={() => {
                this.props.history.push({
                  pathname: "/login_google",
                });
              }}
            >
              <View
                style={{
                  backgroundColor: "#fff",
                  paddingTop: "0.5rem",
                  paddingRight: "0.5rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "0.5rem",
                  marginTop: 10,
                  borderRadius: 23,
                  borderColor: "#ced4da",
                  borderWidth: "1px",
                  width: window.innerWidth * 0.7,
                  textAlign: "center",
                }}
              >
                <Text style={{ color: "#6a737c", fontSize: "18px" }}>
                  {" "}
                  <Image
                    style={{
                      width: 18,
                      height: 18,
                      margin: "auto",
                    }}
                    source={require("../assets/icon-google.png")}
                  />{" "}
                  Google
                </Text>
              </View>
            </TouchableOpacity> */}
            {/* <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 5,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
            </View> */}
            {/* <TouchableOpacity
              activeOpacity={0.8}
              onPress={() =>
                this.props.history.push({
                  pathname: "/register",
                })
              }
            >
              <Text style={styles.buttonOutlineInfo}>
                &nbsp; {this.state.isLoading ? "Loading..." : "Register"}
              </Text>
            </TouchableOpacity> */}

            <View style={styles.tocContainer}>
              <TouchableOpacity
              // onPress={e => {
              //   this.props.navigation.navigate("TermsOfService");
              // }}
              >
                <Text style={{ textAlign: "center", fontSize: 13 }}>
                  {/* By clicking either "Register" or "Continue with Facebook" */}
                  By clicking "Login" button, you truly state to agree with{" "}
                  <Text style={{ color: "rgb(0, 168, 255);" }}>
                    School Talk Terms, Condition, and Privacy Policy
                  </Text>
                </Text>
              </TouchableOpacity>
            </View>

            <View style={{ flex: 1 }} />
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  }
}

const LOGIN = gql`
  mutation logInWithouExpiration(
    $username: String!
    $password: String!
    $installationId: String!
  ) {
    logInWithouExpiration(
      username: $username
      password: $password
      installationId: $installationId
    ) {
      _id
      token
      expiresIn
      Account {
        _id
        name
        email
        phone
        status
        roles
        subscribedTopics
      }
      _createdAt
    }
  }
`;

const RESUBSCRIBE = gql`
  mutation resubscribeTopicForPushNotification($fcmToken: String!) {
    resubscribeTopicForPushNotification(fcmToken: $fcmToken)
  }
`;
export default (props) => (
  <ApolloConsumer>
    {(client) => (
      <Mutation mutation={RESUBSCRIBE}>
        {(resubscribeTopicForPushNotification) => (
          <Mutation mutation={LOGIN}>
            {(logInWithouExpiration) => (
              <Login
                {...props}
                client={client}
                logInWithouExpiration={logInWithouExpiration}
                resubscribeTopicForPushNotification={
                  resubscribeTopicForPushNotification
                }
              />
            )}
          </Mutation>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
);
